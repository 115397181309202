<script>
import { authMethods } from '@state/helpers'
import AuthPageCard from "@components/auth/AuthPageCard";
export default {
  components: {AuthPageCard},
  page: {
    title: 'Ενεργοποίηση λογαριασμού',
    meta: [{ name: 'description', content: `Ενεργοποίηση λογαριασμού στο athlisis` }],
  },
  data() {
    return {
        email: ''
    }
  },
  computed: {
  },
  methods: {
    ...authMethods,
  },
}
</script>

<template>
  <div id="confirm">
    <auth-page-card>
      <div class="mt-3 text-center">
        <h3>Ο λογαριασμός σας δημιουργήθηκε επιτυχώς.</h3>
        <p v-if="!isEducational" class="text-muted mt-2"> Ένα e-mail έχει αποσταλλεί στην ηλεκτρονική διεύθυνση <span class="font-weight-medium">{{ email }}</span>.
          Παρακαλώ ελέγξτε για ένα e-mail και πατήστε στο σύνδεσμο ενεργοποίησης για να ολοκληρώσετε την εγγραφή σας. </p>
        <router-link tag="a" to="/login" class="btn btn-block btn-primary waves-effect waves-light mt-3">Επιστροφή στη σύνδεση</router-link>
      </div>
    </auth-page-card>
  </div>
</template>

<style lang="scss" module></style>
